.profile-popover {
    width: calc(16.66666667% - 50px);

    .ant-popover-arrow-content {
        display: none;
    }

    .ant-popover-inner {
        background-color: #e8eaf5;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
    }
}