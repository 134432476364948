@tabs-card-gutter: 0;
@tabs-card-height: 80px;
@tabs-bar-margin: 0 0 0 0;
@tabs-hover-color: #09243a;

.button-tabs {
  width: 100%;
}

.button-tabs .ant-tabs-nav-wrap {
  width: 100%;
}

.button-tabs .ant-tabs-nav-list {
  // width: 100%;
}

.button-tabs .ant-tabs-tab {
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 32px;

  margin: 0;
  padding: 4px 25px 0px;

  font-family: 'Nexa Regular';
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;

  background-color: transparent;
  color: #000000;
}

@media (max-width: 1199px) {
  .button-tabs .ant-tabs-tab {
    height: 21px;
    padding: 4px 20px 0px;
    font-size: 12px;
    line-height: 16px;
  }
}

// @media (max-width: 991px) {
//   .button-tabs .ant-tabs-tab {
//     padding: 2px 25px 0;
//   }
// }

@media (max-width: 767px) {
  .button-tabs .ant-tabs-tab {
    padding: 4px 15px 0;
    // font-size: 13px;
  }
}

.button-tabs .ant-tabs-nav-list {
  padding: 2px 3px 2.5px;
  border-radius: 18px;
  background-color: #eeedf0;
  overflow: hidden;
}

.button-tabs .ant-tabs-tab-active {
  box-shadow: 0px 3px 8px #00000029;
  border: 0.5px solid #0000000A;
  border-radius: 16px;
  background-color: #fff;
}

.button-tabs .ant-tabs-ink-bar {
  display: none;
}

.button-tabs .ant-tabs-nav-more {
  display: none;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border: none;
}

.button-tabs--large {
  .ant-tabs-tab {
    height: 46px;
    font-size: 18px;

    @media (max-width: 1199px) {
      height: 40px;
      font-size: 16px;
    }

    @media (max-width: 767px) {
      height: 36px;
      font-size: 14px;
    }
  }

  .ant-tabs-nav-list {
    border-radius: 25px;
  }

  .ant-tabs-tab-active {
    border-radius: 23px;
  }
}

.button-tabs--bottom > .ant-tabs-nav,
.button-tabs--bottom > div > .ant-tabs-nav {
  order: 1;
  margin-top: 16px;
  margin-bottom: 0;
}
