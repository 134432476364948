.onboarding-steps {
  z-index: 1;

  position: relative;

  // max-height: calc(100vh - 143px);

  @media (max-width: 991px) {
    display: none !important;
    // height: auto;
  }

  .ant-steps-item {
    height: 70px;
    margin-left: 17px;
    
    @media (max-width: 1550px) {
      height: 55px;
    }

    @media (max-width: 1199px) {
      margin-left: 13px;
    }

    // @media (max-width: 991px) {
    //   margin-left: 0;
    //   padding-top: 5px;
    //   padding-left: 0 !important;
    // }
  }

  // .big-step {
  //   margin-left: 0px;
  //   padding-top: 0;

  //   .ant-steps-item-icon {
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;

  //     width: 46px !important;
  //     height: 46px !important;

  //     border: 1px solid #0000000a !important;

  //     box-shadow: 0px 3px 8px #78788029 !important;
  //     background-color: #fe8e75 !important;

  //     @media (max-width: 991px) {
  //       width: 25px !important;
  //       height: 25px !important;
  //       box-shadow: none !important;
  //     }

  //     svg {
  //       @media (max-width: 991px) {
  //         height: 10px !important;
  //         font-size: 10px !important;
  //       }
  //     }
  //   }

  //   .ant-steps-icon {
  //     display: flex !important;
  //     justify-content: center;
  //     align-items: center;
  //   }

  //   .ant-steps-item-tail {
  //     left: 22.5px !important;
  //     padding: 46px 0 0 !important;

  //     &::after {
  //       width: 2px !important;
  //       border-radius: 0 !important;
  //       background-color: #fe8e75 !important;
  //     }
  //   }

  //   .ant-steps-item-title {
  //     padding-top: 16px;

  //     font-family: "Nexa Bold", Arial !important;
  //     font-size: 20px !important;
  //     line-height: 20px !important;

  //     color: #08243b !important;

  //     @media (max-width: 991px) {
  //       padding: 0;
  //     }

  //     &::after {
  //       @media (max-width: 991px) {
  //         top: -13px;
  //         left: 25px;
  //       }
  //     }
  //   }

  //   .ant-steps-item-icon {
  //     margin-right: 12px !important;

  //     background-color: #ffffff !important;

  //     @media (max-width: 991px) {
  //       margin-right: 0 !important;
  //     }
  //   }

  //   &--active {
  //     .ant-steps-item-icon {
  //       border: 1px solid #fe8e7533 !important;
  //       box-shadow: 0px 3px 8px #fe8e75b3 !important;
  //       background-color: #fe8e75 !important;

  //       @media (max-width: 991px) {
  //         box-shadow: none !important;
  //       }
  //     }
  //   }
  // }

  .ant-steps-item-icon {
    display: flex;
    justify-content: center;
    align-items: center;

    top: 0 !important;
    width: 16px !important;
    height: 16px !important;
    margin-right: 33px !important;

    border: none !important;

    background-color: #E5E5E5 !important;

    @media (max-width: 1550px) {
      width: 13px !important;
      height: 13px !important;
      margin-right: 27px !important;
    }

    // @media (max-width: 991px) {
    //   box-shadow: none !important;
    // }
  }

  .ant-steps-icon {
    display: none !important;
  }

  .ant-steps-item-tail {
    left: 7px !important;
    padding: 16px 0 0 !important;

    @media (max-width: 1550px) {
      left: 5.5px !important;
      padding: 13px 0 0 !important;
    }

    &::after {
      width: 2px !important;
      border-radius: 0 !important;
      background-color: #e5e5e5 !important;
    }
  }

  .ant-steps-item-content {
    min-height: auto !important;
  }

  .ant-steps-item-title {
    padding-right: 0 !important;
    font-family: "Nexa Regular", Arial !important;
    font-size: 17px !important;
    line-height: 22px !important;
    letter-spacing: 0px;

    color: #8096A7 !important;

    @media (max-width: 1550px) {
      font-size: 14px !important;
      line-height: 16px !important;
    }

    // &::after {
      // @media (max-width: 991px) {
      //   top: -8px;
      //   left: 15px;
      //   height: 2px;
      //   background: #e5e5e5;
      // }
    // }
  }

  .ant-steps-item-active,
  .ant-steps-item-finish {
    .ant-steps-item-title {
      color: #000 !important;
    }

    .ant-steps-item-icon {
      background-color: #FE8E75 !important;
      transform: scale(1.6);

      @media (max-width: 1550px) {
        transform: scale(1.6);
      }
    }
  }

  .ant-steps-item-finish {
    .ant-steps-item-title {
      // @media (max-width: 991px) {
      //   &::after {
      //     background: #fd8e74 !important;
      //   }
      // }
    }

    .ant-steps-item-tail {
      &::after {
        background-color: #fe8e75 !important;
      }
    }
  }
}
