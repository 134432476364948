// .notifications-popover {
//   position: fixed !important;
//   top: 64px !important;
//   padding-top: 10px !important;
// }

.notifications-popover .ant-popover-inner-content {
  padding: 0;
  padding-top: 15px;

  background-color: transparent;
}

.notifications-popover .ant-popover-arrow {
  display: none;
}

.notifications-popover .ant-popover-inner {
  background-color: transparent;
  box-shadow: none;
}
