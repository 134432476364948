.ant-modal,
.ant-modal-content {
  border-radius: 15px;
}

@media (max-width: 575px) {
  .ant-modal,
  .ant-modal-content {
    border-radius: 10px;
  }
}

.ant-modal {
  width: 100% !important;
  //max-width: calc(566px + 30px);
  max-width: 70%;
}

.ant-modal-content {
  margin: 0 15px;
}

.ant-modal-body {
  padding: 20px 20px 30px;
  background-color: #ffffff1a !important;
}

@media (max-width: 1199px) {
  padding: 20px 16px 30px;
}

// @media (max-width: 575px) {
//   .ant-modal-body {
//     padding: 20px;
//   }
// }

.ant-modal-close {
  top: 31px;
  right: 31px;
}

@media (max-width: 575px) {
  .ant-modal-close {
    top: 25px;
    right: 25px;
  }
}

.ant-modal-close-x {
  position: relative;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  background-color: #e1e1e1;
}

// @media (max-width: 575px) {
//   .ant-modal-close-x {
//     width: 30px;
//     height: 30px;
//   }
// }

@media (max-width: 575px) {
  .ant-modal-close-x {
    width: 28px;
    height: 28px;
  }
}

.ant-modal-close-icon {
  display: none;
}

.ant-modal-close-x::before,
.ant-modal-close-x::after {
  content: "";

  position: absolute;

  top: 50%;
  left: 50%;

  width: 15px;
  height: 2px;

  background-color: #7f868b;
}

@media (max-width: 575px) {
  .ant-modal-close-x::before,
  .ant-modal-close-x::after {
    width: 12px;
  }
}

.ant-modal-close-x::before {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.ant-modal-close-x::after {
  transform: translate(-50%, -50%) rotate(45deg);
}
