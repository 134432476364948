.ant-notification {
  width: 100%;
  max-width: 422px;
}

.ant-notification-notice {
  padding: 18px 15px;
  border-radius: 10px;
}

.ant-notification-notice-error {
  border: 2px solid #fec4c1;
}

.ant-notification-notice-success {
  border: 2px solid #c0f0cd;
}

.ant-notification-notice-warning {
  border: 2px solid #fedfb3;
}

.ant-notification-notice-icon {
  margin-left: 0;
}

.ant-notification-notice-with-icon .ant-notification-notice-message,
.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 25px;
}

.ant-notification-notice-message,
.ant-notification-notice-description {
  margin-bottom: 0 !important;
  font-family: "Nexa Regular", Arial;
  font-size: 16px;
  line-height: 16px;
}

.ant-notification-notice-message {
  margin-bottom: 0;
  color: #08243b;
}

.ant-notification-notice-description {
  margin-top: 6px;
  color: #8096a7;
}

.ant-notification-notice-description:empty {
  display: none !important;
}

.ant-notification-notice .anticon-close {
  position: relative;
  width: 15px;
  height: 15px;
  border: 1px solid #000000;
  border-radius: 50%;
  opacity: 0.2;
}

.ant-notification-notice .anticon-close::before,
.ant-notification-notice .anticon-close::after {
  content: "" !important;
  display: block !important;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 1px;
  background-color: #000000;
}

.ant-notification-notice .anticon-close::before {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.ant-notification-notice .anticon-close::after {
  transform: translate(-50%, -50%) rotate(45deg);
}

.ant-notification-notice .anticon-close svg {
  display: none;
}

.ant-notification-notice-closable .ant-notification-notice-message,
.ant-notification-notice-closable .ant-notification-notice-description {
  padding-right: 60px;
}