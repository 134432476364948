/* @import '~antd/dist/antd.css'; */
@import '~antd/dist/antd.less';
@import '~antd/lib/style/themes/default.less';
@text-selection-bg: #ACCEF7;
@text-color-inverse: #283549;


@import './styles/layout.css';

@import './styles/antd-table';
@import './styles/antd-rewrite.less';
@import './styles/antd-button.less';
@import './styles/antd-link.less';
@import './styles/antd-typography.less';
@import './styles/antd-tabs.less';
@import './styles/antd-input.less';
@import './styles/antd-radio.less';
@import './styles/antd-checkbox.less';
@import './styles/antd-modal.less';
@import './styles/antd-range-datepicker.less';
@import './styles/antd-popover.less';
@import './styles/antd-switch.less';
@import './styles/antd-select.less';
@import './styles/antd-notifications.less';

@import './styles/fonts.css';

