@checkbox-border-radius: 5px;
@checkbox-border-width: 2px;
@checkbox-group-item-margin-right: 12px;

@radio-top: 0.4em;
@radio-size: 25px;
@radio-border-width: 2px;

.ant-radio-wrapper {
  font-family: "Nexa Regular", Arial;
  font-size: 20px;
  color: #1d1d1f;
}

@media (max-width: 1199px) {
  .ant-radio-wrapper {
    font-size: 17px;
  }
}

span.ant-radio + * {
  padding-left: 15px;
  padding-right: 15px;
}

@media (max-width: 1199px) {
  span.ant-radio + * {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 1199px) {
  .ant-radio {
    top: 0.3em;
  }
}

// .ant-radio-inner {}

@media (max-width: 1199px) {
  .ant-radio-inner {
    width: 20px;
    height: 20px;
  }
}

.ant-radio-inner::after {
  width: 12px;
  height: 12px;
  margin-top: -6px;
  margin-left: -6px;
  transform: none !important;
}

@media (max-width: 1199px) {
  .ant-radio-inner::after {
    width: 10px;
    height: 10px;
    margin-top: -5px;
    margin-left: -5px;
  }
}

.ant-radio-button-wrapper {
  background: none;
  border: none;
  padding: 0;
  color: #1d1d1f;
  opacity: 0.5;

  &:first-child {
    border: none;
  }
  &:focus {
    border: none;
  }
}

.ant-radio-group-small .ant-radio-button-wrapper {
  padding: 0;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #2950af;
  opacity: 1;
  background: none;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio-wrapper:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner.ant-radio-wrapper:hover .ant-radio,
.ant-radio-wrapper:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #8096A7;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #fe8e75 !important;
}