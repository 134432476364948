// Checkbox
@checkbox-size: 20px;
@checkbox-color: @primary-color;
@checkbox-check-color: #fff;
@checkbox-check-bg: #fff;
@checkbox-border-width: 1px;
@checkbox-border-radius: 5px;

.ant-checkbox-wrapper {
  font-family: "Nexa Regular", Arial;
  font-size: 20px;
  // line-height: 147%;
  color: #1d1d1f;
}

.ant-checkbox-group-item {
  margin-right: 0;
}

@media (max-width: 1199px) {
  .ant-checkbox-wrapper,
  .ant-checkbox-wrapper span {
    // font-size: 14px !important;
    font-size: 17px;
  }
}

.ant-checkbox-wrapper-checked .ant-checkbox-inner {
  background-color: #fe8e75;
  border-color: #fe8e75;
}

.ant-checkbox {
  top: 0.3em;
}

@media (max-width: 1199px) {
  .ant-checkbox {
    top: 0.35em;
  }
}

.ant-checkbox + span {
  padding-right: 10px;
  padding-left: 10px;
}

.ant-checkbox-inner {
  box-shadow: inset 0px 1px 2px #0000001a;
  border: 1px solid #00000026;
  border-radius: 2px;
}

.ant-checkbox-inner::after {
  left: 23%;
  top: 45%;
}

.ant-checkbox-group {
  .ant-checkbox-wrapper {
    display: flex;

    // margin-bottom: 15px;

    font-family: "Nexa Regular", Arial;
    font-size: 20px;
    line-height: 29px;

    color: #1d1d1f;
  }

  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
  }

  .ant-checkbox + span {
    padding-left: 15px;
  }
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #8096A7;
}
