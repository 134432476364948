@btn-padding-horizontal-base: 25px;
@btn-padding-horizontal-sm: 20px;

@btn-border-radius-base: 25px;

@btn-height-lg: 50px;
@btn-height-base: 45px;

@btn-font-size-lg: 20px;
@btn-font-size-sm: 20px;

@btn-line-height: 22px;

@btn-primary-bg: @primary-color;
@btn-default-color: @primary-color;

.ant-btn {
  height: 50px;

  padding-top: 0;
  padding-bottom: 0;

  font-family: 'Nexa Regular';
  font-size: 17px;
  line-height: 52px;

  border: 2px solid #283549;
}

@media (max-width: 1199px) {
  .ant-btn {
    height: 40px;

    padding-top: 0;
    padding-bottom: 0;

    font-size: 17px;
    line-height: 40px;
  }
}

.ant-btn[disabled],
.ant-btn[disabled]:hover {
  background-color: #E5E5E5;
  color: #8096A7;
  opacity: 0.5;
}

.ant-btn-lg {
  height: 50px;
  font-family: 'Nexa Regular';
  font-size: 20px;
  line-height: 53px;
}

@media (max-width: 1199px) {
  .ant-btn-lg {
    height: 40px;

    padding-top: 0;
    padding-bottom: 0;

    font-size: 17px;
    line-height: 40px;
  }
}

.ant-btn-sm {
  height: 30px;
  font-size: 17px;
  line-height: 34px;
  border-radius: 25px;
}

@media (max-width: 1199px) {
  .ant-btn-sm {
    height: 30px;
    font-size: 17px;
    line-height: 34px;
  }
}

@media (max-width: 767px) {
  .ant-btn-sm {
    height: 25px;
    font-size: 14px;
    line-height: 27px;
  }
}

// @media (max-width: 575px) {
//   .ant-btn {
//     font-size: 17px;
//     line-height: 17px;
//   }
// }

// .ant-btn-default {
//   font-size: 20px;
//   line-height: 20px;
//   border: 2px solid #80868b;

//   &:hover {
//     background-color: @primary-color;
//     color: #fff;
//   }
// }

// .ant-btn-lg {
//   font-family: "Gotham Pro";
//   font-size: 24px;
//   line-height: 29px;
//   border: 2px solid #D8D9DD;
// }
