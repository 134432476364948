.page {
    padding: 25px 0;
}

.container {
    width: 100%;
    max-width: calc(1764px + 156px);
    margin: 0 auto;
    padding: 0 78px;
}

@media (max-width: 1800px) {
    .container {
        padding: 0 44px;
    }
}

@media (max-width: 1199px) {
    .container {
        padding: 0 20px;
    }
}

@media (max-width: 575px) {
    .container {
        padding: 0 15px;
    }
}

#portal {
    position: fixed;
    z-index: 9000;
}
