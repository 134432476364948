.react-tel-input .form-control {
    width: calc(100% - 55px);
    height: 50px;

    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 55px;

    padding-top: 5px;
    padding-left: 20px;

    font-family: "Nexa Regular", Arial;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: .01rem;

    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.12);
    border-radius: 10px;

    background: #FFFFFF;
    color: #283549;

    outline: none;
}

@media (max-width: 1199px) {
    .react-tel-input .form-control {
        height: 40px;
    }
}

.react-tel-input .flag-dropdown {
    position: absolute;

    top: 0;
    bottom: 0;

    width: 50px;

    padding: 0;

    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.12);
    border-radius: 10px;

    background-color: transparent !important;
}

.react-tel-input .flag-dropdown.open {
    border-radius: 10px;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
    background: transparent;
}

.react-tel-input .flag-dropdown.open .selected-flag {
    background: transparent;
}