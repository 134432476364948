@font-face {
  font-family: "Gotham Pro";
  src: local("Gotham Pro"), url(../fonts/GothamPro/GothamPro-Bold.woff) format("woff");
  font-weight: 700;
}

@font-face {
  font-family: "Circe";
  src: local("Circe"), url(../fonts/Circe/Circe-Regular.woff) format("woff");
}

@font-face {
  font-family: "Circe";
  src: local("Circe"), url(../fonts/Circe/Circe-Bold.woff) format("woff");
  font-weight: 700;
}

@font-face {
  font-family: "Nexa Bold";
  src: local("Nexa Bold"), url(../fonts/NexaBold/Nexa-Bold.woff) format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "Nexa Regular";
  src: local("Nexa Regular"), url(../fonts/NexaBold/Nexa-Regular.woff) format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "Nexa Light";
  src: local("Nexa Light"), url(../fonts/NexaBold/Nexa-Light.woff) format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "SF Pro";
  src: local("SF Pro"), url(../fonts/SFPro/SFProDisplay-Regular.woff) format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "SF Pro";
  src: local("SF Pro"), url(../fonts/SFPro/SFProDisplay-Medium.woff) format("woff");
  font-weight: 500;
}