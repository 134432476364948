.ant-select-open .ant-select-arrow::after {
  transform: translateY(3px) rotate(-135deg);
}

.ant-select-arrow {
  top: 44%;
  right: 26px;
}

.ant-select-arrow > * {
  display: none;
}

.ant-select-arrow::after {
  content: "";

  display: inline-flex;

  width: 14px;
  height: 14px;

  border-right: 2px solid #fe8e75;
  border-bottom: 2px solid #fe8e75;

  transform: translateY(-1px) rotate(45deg);
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  display: flex;
  align-items: center;
  line-height: 1em;
}

.ant-select-selector {
  border-radius: 8px !important;
  border: 1px solid rgba(0, 0, 0, 0.08) !important;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.12) !important;
  background-color: #fff;
}

.ant-select-selection-placeholder,
.ant-select-selection-item {
  padding-top: 13px !important;
  padding-bottom: 7px !important;
}

.ant-select:hover .ant-select-selector {
  border-color: #00000033 !important;
}

// search select
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #FE8E7533 !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 45px;
}