.confirm-password-modal {
  width: 100% !important;
}

.confirm-password-modal .ant-modal-content {
  max-width: 555px !important;
  margin: 0 15px;
}

.confirm-password-modal .ant-modal-body {
  padding: 21px 26px 34px;
}

@media (max-width: 575px) {
  .confirm-password-modal .ant-modal-body {
    padding: 25px 20px;
  }
}
