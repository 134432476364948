.ant-picker {
  padding-top: 14px;
  padding-bottom: 10px;
  padding-left: 0;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.12);
}

.ant-picker-small {
  padding-top: 5px;
  padding-bottom: 5px;
}

.ant-picker-input > input {
  font-size: 15px;
}

.ant-picker-suffix {
  margin-bottom: 3px;
  color: #FE8E75;
}

.ant-picker-suffix svg {
  font-size: 20px;
}