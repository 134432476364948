.general-table {
  flex-grow: 1;
}

.general-table table {
  table-layout: fixed;
  overflow: hidden;
  padding-bottom: 25px;
}

@media (max-width: 1450px) {
  .general-table table {
    width: 1150px !important;
  }
}

.general-table .ant-table-cell:last-child {
  border-right: none;
}

.general-table .ant-table-thead {
  background: none;
}

.general-table .ant-table-cell {
  font-family: "Nexa Regular", Arial;
  font-size: 14px;

  border-radius: 0 !important;

  background: #fff;
}

@media (max-width: 1450px) {
  .general-table .ant-table-cell {
    font-size: 12px;
  }
}

.general-table .ant-table-thead .ant-table-cell {
  position: relative;
  padding: 13px 15px 8px !important;

  line-height: 100%;
  font-family: "Nexa Light", Arial;

  border-bottom: 1px solid #00000040;

  color: #8096a7;
}

@media (max-width: 1450px) {
  .general-table .ant-table-thead .ant-table-cell {
    padding: 11px 15px 6px !important;
  }
}

.general-table .ant-table-thead .ant-table-cell:first-child {
  padding-left: 25px !important;
}

@media (max-width: 1450px) {
  .general-table .ant-table-thead .ant-table-cell:first-child {
    padding-left: 15px !important;
  }
}

.general-table .ant-table-thead .ant-table-cell::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 20px;
  background-color: #0000001a;
  transform: translateY(-50%);
}

.general-table .ant-table-tbody {
  position: relative;
  top: 5px;
  left: 9px;
}

.general-table .ant-table-tbody .ant-table-cell {
  height: 45px;
  padding: 11px 15px 10px;
  padding-right: calc(15px + 9px);
  padding-left: calc(15px - 9px);

  border-bottom: none !important;

  color: #1d1d1f;

  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (max-width: 1450px) {
  .general-table .ant-table-tbody .ant-table-cell {
    height: 35px;
    padding: 7px 15px 4px;
    padding-right: calc(15px + 9px);
    padding-left: calc(15px - 9px);
  }
}

.general-table .ant-table-tbody .ant-table-cell:first-child {
  padding-left: 15px;
}

@media (max-width: 1450px) {
  .general-table .ant-table-tbody .ant-table-cell:first-child {
    padding-left: 8px;
  }
}

.general-table .ant-table-tbody .ant-table-row:nth-child(even) .ant-table-cell {
  background-color: #e5e5e533;
}

.general-table .ant-table-tbody .ant-table-row .ant-table-cell-row-hover {
  background-color: #FE8E7533 !important;
}