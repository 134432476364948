@input-height-base: 50px;
@input-border-color: #00000014;
@input-hover-border-color: #00000033;

.ant-input {
  padding-top: 12px;
  padding-bottom: 7px;
  border-width: 1px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.12);
}

.ant-input-number {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.12);
  width: 100%;
}

.ant-input-textarea-show-count::after {
  margin-bottom: 0px;

  font-family: "Nexa Regular";
  font-size: 14px;
  line-height: 130%;
  text-align: right;
  letter-spacing: 0;
  padding-top: 8px;

  color: #3C3C4366;
}



.ant-input:focus {
  border: 1px solid #00000033;
  box-shadow: 0px 0.5px 0px #0000000f;
}

.ant-input-affix-wrapper {
  border-radius: 10px !important;
}
