.guide-modal {
  width: 100% !important;
  max-width: 596px !important;
}

.guide-modal .ant-modal-content {
  margin: 0 15px;
}

.guide-modal .ant-modal-body {
  padding: 26px;
}

@media (max-width: 575px) {
  .guide-modal .ant-modal-body {
    padding: 25px 20px;
  }
}